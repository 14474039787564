.dropdown-menu {
  border-radius: 15px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.btnNavCustom {
  outline: none !important;
  border-radius: 100% !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: rgba(255, 255, 255, 0.2) !important;
  width: 40px !important;
  height: 40px !important;
}

.btnNavCustom.gray {
  background: rgba(45, 49, 66, 0.05) !important;
  width: 35px !important;
  height: 35px !important;
}

.btnNavCustom.gray:hover {
  background: rgba(45, 49, 66, 0.1) !important;
}

.btnNavCustom:hover {
  outline: none !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.btnNavCustom:focus {
  outline: none !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.leftNavItem {
  display: flex !important;
  align-items: center !important;
}

.leftNavItem:hover .btnLeftNav {
  outline: none !important;
  background: linear-gradient(139.4deg, #50cef8 19.62%, #337ff1 85%) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.leftNavItem:focus .btnLeftNav {
  outline: none;
  background: linear-gradient(139.4deg, #50cef8 19.62%, #337ff1 85%);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.btnLeftNav {
  outline: none !important;
  border-radius: 100% !important;
  width: 28px !important;
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  background: rgba(45, 49, 66, 0.4) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.btnLeftNav.recent {
  background: #2d31420d !important;
}

.btnLeftNav.active {
  outline: none !important;
  background: linear-gradient(139.4deg, #50cef8 19.62%, #337ff1 85%) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.leftNavTitle {
  font-size: 14px !important;
  margin-left: 8px !important;
}

.leftNavTitle.active {
  text-decoration: underline !important;
}

.leftNavItem:hover .leftNavTitle {
  text-decoration: underline !important;
}

.leftNavItem:focus .leftNavTitle {
  text-decoration: underline !important;
}

.btnGroup {
  height: 30px;
  background-color: #4c6181;
  border-color: #4c6181;
  border-radius: 12px !important;
}

.btnGroup .btnCustom {
  background-color: #4c6181;
  border-color: #4c6181;
  font-size: 12px;
  height: 30px;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  margin-right: 1px;
}

.btnGroup .btnCustom:last-child {
  margin-right: 0;
}

.btnGroup .btnCustom.btnActive {
  background: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
  outline: none;
}

.btnGroup .btnCustom:hover {
  background: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
}

.btnGroup .btnCustom:active {
  background: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
  outline: none;
}

.btnGroup .btnCustom:focus {
  background: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
  outline: none;
}

.btnGroup .btnCustom:disabled {
  background-color: #4c6181;
  border-color: #4c6181;
  outline: none;
}

.btn-primary {
  --bs-btn-focus-shadow-rgb: none !important;
}

/* Stepper */
.w-steps .w-steps-item-inner {
  border: 2px solid #2d3142 !important;
  width: 24px !important;
  height: 24px !important;
  margin-right: 16px !important;
}

.w-steps .w-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 13px;
  padding: 0 !important;
  height: 1px;
}

.w-steps-vertical > .w-steps-item > .w-steps-item-tail {
  width: 2px !important;
  left: 11px !important;
}

.w-steps .w-steps-item-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #2d3142 !important;
}

.w-steps-vertical .w-steps-item-main {
  width: calc(100% - 50px) !important;
}

.w-steps-icon {
  display: none !important;
}

.stepItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.stepItem .txt1 {
  color: #2d3142;
  font-size: 13px;
  text-align: left;
}

.stepItem .txt2 {
  color: #4c5980;
  font-size: 11px;
  text-align: left;
  width: 75%;
}

.stepItem .txt3 {
  color: #4c5980;
  font-size: 9px;
  text-align: right;
  width: 22%;
}

.moreActivity {
  color: #337ff1;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.customSelect {
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.customSelect:focus {
  border-bottom: 1px solid #ced4da !important;
}

.transactionStep {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.transactionStep .item {
  display: flex;
  align-items: center;
}

.transactionStep .item .icon {
  width: 32px;
  height: 32px;
}

.transactionStep .item .desc {
  margin-left: 16px;
}

.transactionStep .txt1 {
  color: #2d3142;
  font-size: 16px;
  text-align: left;
}

.transactionStep .txt2 {
  color: #4c5980;
  font-size: 14px;
  text-align: left;
}

 .txtPrice {
  color: #2d3142;
  font-size: 16px;
  text-align: right;
}

.txtPrice.green {
  color: #2cd19c;
}

.w-steps .w-steps-item-title {
  width: 100%;
  padding-right: 0 !important;
}
