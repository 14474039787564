@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/FontsFree-Net-SFProText-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'SF Pro Text', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100vh;
  width: 100vw;
}

::-webkit-input-placeholder {
  font-size: 16px !important;
  color: #2d3142 !important;
  opacity: 0.2 !important;
}

.form-control {
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  z-index: 100;
}

.form-control:focus {
  border-bottom: 1px solid #ced4da !important;
}

.form-switch input[type='checkbox'] {
  cursor: pointer;
}

p {
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}
