
div.inline 
{
  width: 100%;
}

div.open .rdrDateDisplayWrapper
{
  display: none;
}

div.inline .rdrMonthAndYearWrapper,
div.inline .rdrMonthsVertical,
div.inline .rdrMonths,
div.inline .rdrDefinedRangesWrapper
{
  display: none;
}

div.inline .rdrDateDisplayWrapper,
div.inline .rdrCalendarWrapper {
  width: 100%;
  background-color: transparent;
}




.popover {
  max-width: 800px !important;
  border-radius: 0em !important;
}

