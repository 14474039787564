.wrapper {
  width: 100%;
  height: 100%;
}

/* Login Form */

.login-container {
  width: 100%;
}

.login-left-content {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
}

.login-logo {
  width: auto;
  height: 52px;
  margin-bottom: 150px;
}

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-form {
  width: 100% !important;
}

.login-form .title {
  font-size: 28px;
  color: #171717;
  font-weight: 600;
  margin-bottom: 20px;
}

.login-form .info-txt {
  font-size: 14px;
  color: #171717;
  margin-bottom: 20px;
}

.login-form .form-label {
  font-size: 14px;
  color: #171717;
}

.login-form .form-input {
  border-radius: 0.375rem !important;
  padding: 0.375rem 0.75rem !important;
  border-width: 1px !important;
  font-size: 14px !important;
  height: 40px !important;
}

.login-form .form-input:focus {
  border-bottom: 1px solid #86b7fe !important;
}

.login-form .btn-form {
  border-radius: 6px !important;
  background: linear-gradient(139.4deg, #50cef8 19.62%, #337ff1 85%) !important;
  border: none !important;
  font-size: 16px !important;
  height: 44px;
}

.login-form .cancel-btn {
  border: 1px solid #6c757d !important;
  background-color: #fff !important;
  height: 36px;
  color: #6c757d !important;
}

.login-form .cancel-btn:hover {
  opacity: 0.75;
}

.login-form .btn-form:hover {
  opacity: 0.75;
}

.login-form .option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-form .remember {
  display: flex;
  align-items: center;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.login-form .form-check-input {
  width: 20px !important;
  height: 20px !important;
}

.login-form .form-check-label {
  font-size: 14px !important;
  color: #404040 !important;
  padding-top: 3px !important;
}

.login-form .reset-txt {
  font-size: 14px;
  color: #50cef8;
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

.login-form .reset-txt:hover {
  color: #50cef8;
  opacity: 0.6;
}

.sign-up {
  margin-top: 26px;
  font-size: 14px;
}

.sign-up .txt-1 {
  color: #737373;
}

.sign-up .txt-2 {
  color: #50cef8;
  cursor: pointer;
  text-decoration: none;
}

.sign-up .txt-2:hover {
  opacity: 0.6;
  color: #50cef8;
}

.login-right-content {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.background-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.login-right-content .title {
  font-size: 36px;
  color: #fff;
  line-height: 44px;
  padding-left: 93px;
  padding-right: 59px;
  font-weight: bold;
  margin-bottom: 28px;
}

.login-right-content .subtitle {
  padding-left: 93px;
  padding-right: 59px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 57px;
}

.login-right-content .img-content {
  width: 100%;
  height: 100%;
}

.carousel {
  height: 100% !important;
}

.carousel-inner {
  height: 100% !important;
}

.carousel-item {
  height: 100% !important;
}

.carousel-indicators {
  top: -80px !important;
  justify-content: left !important;
  margin-left: 93px !important;
}

.carousel-indicators button {
  border-radius: 100% !important;
  width: 16px !important;
  height: 16px !important;
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.carousel-item {
  padding-left: 93px !important;
}

.btn-backround {
  background: linear-gradient(139.4deg, #50cef8 19.62%, #337ff1 85%);
  border: none !important;
  height: 38px;
  border-radius: 12px;
  outline: none;
}

/* Admin */
.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.admin-header .badge {
  padding: 2px 4px;
  border-radius: 16px;
  font-size: 8px;
  color: #fff;
  margin-left: 4px;
  background-color: rgba(255, 255, 255, 0.4);
}

.admin-setting-header {
  color: white;
  text-decoration: none;
  margin-right: 0.5em;
  cursor: pointer;
}

.client-details {
  width: 100%;
  padding: 1em 2em;
}

.client-details .title {
  font-size: 24px;
  color: #2d3142;
  margin-bottom: 25px;
}

.client-details .label {
  font-size: 12px;
  color: #4c5980;
}

.client-details .card-content {
  width: 100%;
  padding: 24px;
  border-radius: 13px;
  background-color: rgba(45, 49, 66, 0.05);
}

.client-details .asset-label {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.client-settings {
  width: 100%;
}

.client-settings .title {
  font-size: 24px;
  color: #2d3142;
  margin-bottom: 36px;
}

.client-settings .label {
  font-size: 12px;
  color: #4c5980;
}

.row-item-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-item {
  display: flex;
  align-items: center;
}

.input-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 68px;
}

.client-settings .result-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ced4da;
  padding: 0 20px;
}

.client-settings .result-header .txt {
  font-size: 12px;
  color: #4c5980;
  margin-bottom: 8px;
}

.client-settings .result-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.client-settings .result-row .item {
  display: flex;
  align-items: center;
}

.client-settings .result-row .item .icon {
  width: 48px;
  height: 48px;
}

.client-settings .result-row .item .txt {
  margin-left: 16px;
  font-size: 16px;
  color: #2d3142;
  font-weight: 500;
  margin-bottom: 0;
}

.client-settings .result-row .item .icon-action {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.logo-item {
  width: 220px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #50cef8;
  border-radius: 10px;
  cursor: pointer;
}

.logo-item .icon {
  width: 30px;
  height: 38px;
  color: #50cef8;
  margin-bottom: 19px;
}

.logo-item .txt {
  font-size: 12px;
  color: #0d6efd;
  font-weight: 500;
  margin-bottom: 0;
}

.default-logo-item {
  width: 220px;
  height: 160px;
  cursor: pointer;
  object-fit: contain;
}

.default-logo-item-small {
  width: 100%;
  height: 100px;
  cursor: pointer;
  object-fit: contain;
}

.logo-info {
  padding: 9px 11px;
}

.logo-info .txt-1 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.logo-info .txt-2 {
  font-size: 14px;
  color: #4c5980;
}

.logo-info .txt-3 {
  font-size: 16px;
  color: #000;
}

.client-settings .sub-title {
  font-size: 20px;
  color: #2d3142;
  font-weight: 500;
  padding-bottom: 23px;
  border-bottom: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client-settings .txt-16 {
  font-size: 16px;
  color: #2d3142;
  font-weight: 500;
}

.client-settings .txt-12 {
  font-size: 12px;
  color: #4c5980;
  font-weight: 500;
  margin-bottom: 0;
}

.client-settings .plaid-img {
  width: 130px;
  height: 50px;
  margin-top: 13px;
}

/* Billings */
.billing-cycle-title {
  font-size: 14px;
  font-weight: 500;
  color: #4c5980;
  margin-bottom: 14px;
}

.billing-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

.billing-info-row .datepicker-content {
  position: relative;
  width: 300px;
}

.billing-info-row .datepicker-content .picker-info {
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: #9f9f9f !important;
  font-size: 16px !important;
}

.billing-info-row .datepicker-content .icon-calendar {
  position: absolute;
  bottom: 10px;
  right: 30px;
  color: #0d6efd;
}

.billing-info-row .total {
  font-size: 30px;
  color: #2d3142;
  font-weight: bold;
}

/* Manage Payment */
.payment-status-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

.payment-card-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.payment-card-item {
  width: calc(260px - 8px);
  height: auto;
  padding: 20px 16px;
  border: 1px solid #0d6efd;
  border-radius: 12px;
  margin-right: 16px;
}

.payment-card-item:last-child {
  margin-right: 0;
}

.payment-card-item .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.payment-card-item .header .icon {
  width: 40px;
  height: 18px;
  cursor: pointer;
}

.payment-card-item .bank-name {
  font-size: 20px;
  font-weight: 500;
  color: #2d3142;
  margin-bottom: 10px;
}

.payment-card-item .card-num {
  font-size: 16px;
  font-weight: 500;
  color: #2d3142;
}

.payment-status-row .txt-status {
  font-size: 16px;
  font-weight: 500;
}

.profile-header {
  display: flex;
  align-items: center;
}

.profile-header .txt {
  font-size: 14px;
  font-weight: 600;
  color: #2d3142;
  margin-right: 11px;
}

.profile-user-info {
  display: flex;
  align-items: center;
  padding-bottom: 36px;
  border-bottom: 1px solid #ced4da;
}

.profile-user-info .profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid #50cef8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.profile-user-info .full-name {
  font-size: 24px;
  color: #2d3142;
  font-weight: 600;
  margin-bottom: 10px;
}

.profile-user-info .other-info {
  font-size: 16px;
  color: #2d3142;
}

.profile-other-info {
  padding: 44px 0 36px 0;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 44px;
}

.profile-other-info .title {
  font-size: 14px;
  color: #4c5980;
}

.profile-other-info .content-txt {
  font-size: 14px;
  color: #2d3142;
  font-weight: 600;
  margin-left: 17px;
}

.profile-change-password-txt {
  font-size: 14px;
  color: #2d3142;
  margin-left: 7px;
}

.profile-delete-logout-account-txt {
  font-size: 14px;
  color: #d75053;
  margin-left: 7px;
}

.edit-profile {
  padding: 50px 80px;
}

.modal-width {
  max-width: none !important;
  width: 900px !important;
}

@media (min-width: 320px) and (max-width: 768px) {
  .login-container {
    display: block !important;
  }

  .login-container > div:nth-child(1) {
    height: 100% !important;
  }

  .login-container > div:nth-child(2) {
    display: none !important;
  }

  .login-left-content {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .login-logo {
    margin-bottom: 70px !important;
  }

  .login-form .title {
    text-align: center !important;
  }

  .sign-up {
    text-align: center !important;
  }
}

/* Login Form End */


/*Calendar */

.important-date {
  background-color: rgb(130, 227, 164) !important; 
}

.important-date-red {
  background-color: rgb(242, 152, 144) !important; 
}
